.headline {
  font-size: 60px;
  font-weight: 300;
  display: block;
  text-align: center;
  margin-top: calc(50vh - 60px);
  .char {
    cursor: pointer;
  }
}

.skill {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  visibility: hidden;
  position: absolute;

  &.act {
    visibility: visible;

    &.desc {
      font-size: 15px;
      text-align: center;
      display: block;
      position: relative;
      padding-top: 3px;
      span {
        font-size: 25px;
      }
    }

  }
}

.headline strong,
.skill strong {
  font-weight: 700;
  text-transform: uppercase;
}

// body.home {
//   color: $white;
//   a {
//     color: $white;
//   }
//   .container {
//     position: absolute;
//     height: 100vh;
//     width: 100vw;
//     background: url(../images/bg.jpg) center center no-repeat;
//     background-size: 100vw;
//   }
// }


@media screen and (max-width:$tablet-max-width) {

  .headline {
    font-size: 50px;
  }

  .skill {
    font-size: 35px;
    left: auto!important;
    margin-left: 0!important;
    text-align: center;
    width: 100%;
  }

  body.home {
    .container {
      background-size: cover;
    }
  }

}

@media screen and (max-width:$phone-max-width) {

}
