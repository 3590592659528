// Colors

$white: #ffffff;
$black: #000000;
$brand: violet;

$icon-totop-bg: $white;

$phone-max-width: 768px;
$tablet-max-width: 960px;
$full-width-break: 1200px;

// @mixin gradient() {
//   background: transparent; /* For browsers that do not support gradients */
//   background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.75), rgba(255,255,255,0)); /* For Safari 5.1 to 6.0 */
//   background: -o-linear-gradient(top, rgba(0,0,0,0.75), rgba(255,255,255,0)); /* For Opera 11.1 to 12.0 */
//   background: -moz-linear-gradient(top, rgba(0,0,0,0.75), rgba(255,255,255,0)); /* For Firefox 3.6 to 15 */
//   background: linear-gradient(to top, rgba(0,0,0,0.75), rgba(255,255,255,0)); /* Standard syntax (must be last) */
// }

