/* clearfix */
@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}



@mixin placeholder($color) {
  &::-webkit-input-placeholder {
	color: $color;
  }

  &:-moz-placeholder { /* Firefox 18- */
	color: $color;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
	color: $color;
  }

  &:-ms-input-placeholder {
	color: $color;
  }
}
