@charset "UTF-8";

/*
Theme Name: IM - INSERTCOMPANY - Theme
Description: A custom Wordpress Themes.
Version: 1.0
Author: Florian Langer
Text Domain: imtheme
*/

@import '../../../node_modules/reset-css/_reset.scss';

@import "_constants";
@import "_functions";

// @font-face {
//     font-family: "THE_FONT_NAME";
//     src: url('include/font/THE_FONT_FILE_NAME.eot');
//     src: url('include/font/THE_FONT_FILE_NAME.eot?#iefix') format('embedded-opentype'),
//          url('include/font/THE_FONT_FILE_NAME.woff') format('woff'),
//          url('include/font/THE_FONT_FILE_NAME.ttf') format('truetype');
// }

* {
  box-sizing: border-box;
}

body{
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-size: 16px;
}

body .cc-revoke,
body .cc-window {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  border-top: 1px solid black;
}

body .cc-banner .cc-btn:last-child {
  min-width: 60px;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 25px;
}

h4 {
  font-size: 20px;
}

h1, h2, h3, h4 {
  margin-top: 10px;
  margin-bottom: 20px;
  .upper {
    text-transform: uppercase;
    font-weight: 700;
  }
}

p {
  margin-bottom: 20px;
  line-height: 1.4;
}

a {
  color: #000000;
  text-decoration: underline;
}

.container {
  position: relative;
  a:hover {
    text-decoration: none;
  }
}

header {
  position: relative;
}

header,
footer {
  @include clearfix();
  z-index: 100;
  a {
    display: block;
    height: 24px;
    line-height: 24px;
    text-decoration: none;
    span {
      display: inline;
      line-height: 24px;
      text-decoration: underline;
    }
    &:hover span {
      text-decoration: none;
    }
  }

  img,
  svg {
    display: inline-block;
    line-height: 24px;
    vertical-align: top;
  }
}

header {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 2vw;

  .mail,
  .insta {
    float: right;
    margin-left: 2vw;
  }

  .home,
  .user {
    float: left;
    margin-right: 2vw;
  }
}

#totop {
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  z-index: 1000;
  line-height: 24px;
  height: 24px;
  background-color: $icon-totop-bg;
  display: block;
  border-radius: 100%;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 2vw;

  .info {
    float: left;
    margin-right: 2vw;
    line-height: 24px;
  }

}

@import "home";
@import "imprint";
@import "vendor/timeline";
@import "cv";

