.cv {

  .container {
    position: relative;
    max-width: 100%;
    padding: 0 2vw;
    margin: 0 auto;
  }

  header,
  footer {
    position: relative;
  }

  .timeline {
    margin-bottom: 30px;
  }

  .timeline-nav-button {
      &--prev {
          &::before {
              background-image: url('../images/chevron-left.svg');
          }
      }
      &--next {
          &::before {
              background-image: url('../images/chevron-right.svg');
          }
      }
  }

  .cvBlock {
    padding: 0 2vw;
    p {
      padding: 0 2vw;
    }
    img {
      margin: 0 2vw;
      margin-bottom: 20px;
      max-width: 200px;
    }
  }

}


@media screen and (max-width:$tablet-max-width) {

  .cv {
    .container {
      padding: 2vw;
    }

    .timeline {
      margin: 0 4vw;
    }

    footer {
      position: relative;
    }

    .cvBlock {
      img {
        max-width: 88vw;
        width: 88vw;
      }
    }
  }

}

@media screen and (max-width:$phone-max-width) {
  .cv {
    .container {
      padding: 2vw;
    }

    footer {
      position: relative;
    }

    .cvBlock {
      img {
        max-width: 88vw;
        width: 88vw;
      }
    }
  }
}
