.imprint {

  .container {
    position: relative;
    max-width: 960px;
    padding: 0 2vw;
    margin: 0 auto;
  }

  header,
  footer {
    position: relative;
  }
}
